import React from "react"
import { graphql } from 'gatsby'
import AboutLayout from "../../components/layouts/aboutLayout"
import moment from 'moment'
import { css, jsx } from "@emotion/core"

const LargeCalendarIcon = ({ date }) => {

  var m = moment(date)

  return (
    <div css={{
      border: "1px solid rgb(171, 173, 179)",
      textAlign: "center",
      minWidth: "7rem",
      backgroundColor: "#fff",
      marginRight: `2rem`
    }}>
    <div css={{
      backgroundColor: "rgb(171, 173, 179)", fontWeight: "600"
    }}>{m.format("MMMM")}</div>
  <div css={{
    fontSize: "1.8rem",
    fontWeight: "600",
    padding: "0.4rem 0 0rem 0",
    lineHeight: "1.8",
  }}>{m.format("DD")}</div>
      <div css={{paddingBottom: "0.4rem"}}>{m.format("dddd")}</div>
    </div>
  )
}






const LargeEventCard = ({date, desc, details, endTime, startTime, title, location}) => {
  var eventDate = moment(date)

  return (
    <div css={{
      display: "flex",
      alignItems: 'flex-start',
      borderBottom: `1px dotted #000`,
      padding: `2rem 1rem`
    }}>
      <LargeCalendarIcon date={eventDate} />
      <div>
        <div>
          <span>{eventDate.format("dddd, MMMM Do YYYY")}</span>
          <h2>{title}</h2>
          <small css={{display: 'block', marginTop: `-0.005rem`}}><em>{eventDate.format("h:mm a")} at {location}</em></small>
        </div>
        <div css={{marginTop: `1rem`}}>{desc}</div>
      </div>
    </div>
  )
}



const CalendarPage = ({ data })  => {
  const now = moment()
  const eventsMap = data.allEventsYaml.nodes
    .filter(i => moment(i.endDate).isSameOrAfter(now))
    .map(i => <div><LargeEventCard {...i}/></div>)

  return(
    <AboutLayout pageTitle="Events Calendar">
      {eventsMap}
    </AboutLayout>
  )
}

export const query = graphql`
  query EventsQuery {
    allEventsYaml(sort: {order: ASC, fields: date}) 
    {
      nodes {
        date
        endDate
        desc
        details
        endTime
        startTime
        title
        location
      }
    }
  }
`

export default CalendarPage
